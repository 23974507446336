@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
	@font-face {
		font-family: 'dm_sansbold';
		src: url('../public/fonts/dmsans/DMSans-Bold.woff2') format('woff2'),
			url('../public/fonts/dmsans/DMSans-Bold.woff') format('woff');
		font-weight: normal;
		font-style: normal;
		font-display: swap;
	}

	@font-face {
		font-family: 'dm_sansregular';
		src: url('../public/fonts/dmsans/DMSans-Regular.woff2') format('woff2'),
			url('../public/fonts/dmsans/DMSans-Regular.woff') format('woff');
		font-weight: normal;
		font-style: normal;
	}

	@font-face {
		font-family: 'dm_sansmedium';
		src: url('../public/fonts/dmsans/DMSans-Medium.woff2') format('woff2'),
			url('../public/fonts/dmsans/DMSans-Medium.woff') format('woff');
		font-weight: normal;
		font-style: normal;
	}

	.freeze-first-row {
		position: sticky;
		top: 0;
		background-color: white;
		z-index: 1;
	}

	.freeze-first-column {
		position: sticky;
		left: 0;
		background-color: white;
		z-index: 1;
	}

	.text-gradient-primary-css {
		background-clip: text;
		color: transparent;
		background-image: linear-gradient(to right, #FCEE21, #F7931E);
		font-weight: 800 !important;
	}
}

.text-gradient-primary {
	@apply font-extrabold text-[16px] text-transparent bg-clip-text bg-gradient-to-r from-[#FCEE21] to-[#F7931E];
}

/* button styles */
.btn-primary {
	@apply bg-gradient-to-br from-[#FCEE21] to-[#F7931E] py-1 px-3 text-black rounded-[4px] font-semibold shadow-md hover:opacity-75;
}

.btn-primary-disable {
	@apply bg-gradient-to-tl bg-[#c2c6cc] py-1 px-3 text-white rounded-[4px] font-semibold shadow-md hover:opacity-75;
}

.btn-primary-rounded-less {
	@apply py-1 px-3 bg-gradient-to-br from-[#FCEE21] to-[#F7931E] text-black font-semibold rounded-[4px] shadow-md hover:opacity-75 focus:outline-none;
}

.btn-primary-rounded-less-disable {
	@apply py-1 px-3 bg-[#c2c6cc] text-white font-semibold rounded-[4px] shadow-md hover:bg-[#c2c6cc]/[0.7] focus:outline-none;
}

.btn-primary-rounded {
	@apply py-1 px-3 bg-gradient-to-br bg-[#c2c6cc] text-white font-semibold rounded-[14px] shadow-md hover:bg-[#F7931E]/[0.7] focus:outline-none;
}

.btn-white-rounded-full {
	@apply bg-white rounded-full py-1 px-3 font-semibold shadow-md hover:opacity-75;
}

.btn-white-rounded {
	@apply bg-white rounded-[4px] py-1 px-3 font-semibold shadow-md hover:opacity-75;
}

.btn-black-rounded-full {
	@apply bg-[#202020] rounded-full py-1 px-3 font-semibold shadow-md hover:opacity-75;
}

.btn-black-rounded {
	@apply bg-[#202020] rounded-[4px] py-1 px-3 font-semibold shadow-md hover:opacity-75;
}

.btn-border-white-rounded {
	@apply border-white border-[1px] py-1 px-3 text-white rounded-[4px] font-semibold shadow-md hover:opacity-75;
}

.btn-border-black-rounded {
	@apply border-black border-[1px] py-1 px-3 text-black rounded-[4px] font-semibold shadow-md hover:opacity-75;
}

.btn-primary-rounded-disable {
	@apply py-1 px-3 bg-[#c2c6cc] text-white font-semibold rounded-[14px] shadow-md hover:bg-[#c2c6cc]/[0.7] focus:outline-none;
}

.btn-error {
	@apply py-1 px-3 bg-[#e34242] text-white font-semibold shadow-md hover:bg-[#e34242]/[0.7] focus:outline-none;
}

.btn-primary-text {
	@apply py-1 px-3 bg-transparent text-[#F7931E] font-semibold hover:opacity-75 focus:outline-none;
}

.btn-primary-text-underline {
	@apply inset-0 bg-transparent text-[#F7A21E] underline focus:outline-none hover:opacity-75 font-semibold py-1 px-3;
}

.btn-primary-text-underline-disable {
	@apply py-1 px-3 underline bg-transparent text-[#c2c6cc] font-semibold hover:opacity-75 focus:outline-none;
}

.btn-green-gradient {
	@apply bg-gradient-to-br from-[#FCEE21] to-[#F7931E] py-1 px-3 text-black rounded-[4px] font-semibold shadow-md hover:opacity-75;
}

.btn-green-gradient-disable {
	@apply bg-gradient-to-tl bg-[#c2c6cc] py-1 px-3 text-white rounded-[4px] font-semibold shadow-md hover:opacity-75;
}

.btn-filters {
	@apply flex flex-row justify-evenly items-center py-1 px-3 bg-gradient-to-br from-[#FCEE21] to-[#F7931E] rounded-[14px] text-black font-semibold hover:opacity-75 focus:outline-none;
}

.btn-noactive-filters {
	@apply flex flex-row justify-evenly items-center py-1 px-3 bg-[#f9fbfc] rounded-[14px] border border-solid border-[#3c3c434d] rounded-[14px] text-[#3c3c434d] font-semibold hover:opacity-75 focus:outline-none;
}

.btn-categories {
	@apply flex flex-row justify-evenly items-center py-1 px-3 bg-white text-[#F7A21E] font-semibold rounded-[14px] hover:bg-[#fafafa]/[0.7] focus:outline-none;
}

.btn-noactive-categories {
	@apply flex flex-row justify-evenly items-center py-1 px-3 bg-[#f7f9fb] rounded-[14px] text-[#c2c6cc] font-semibold hover:text-[#a4a4a4]/[0.9] focus:outline-none;
}

.btn-categories-list {
	@apply flex flex-row justify-start gap-2 items-center py-1 px-3 bg-gradient-to-br from-[#FCEE21] to-[#F7931E] !text-[#202020] font-semibold rounded-[14px] hover:opacity-75 focus:outline-none;
}

.btn-noactive-categories-list {
	@apply flex flex-row justify-start gap-2 items-center py-1 px-3 bg-[#f7f9fb] rounded-[14px] text-[#c2c6cc] font-semibold hover:text-[#a4a4a4]/[0.9] focus:outline-none;
}

.btn-grey-rounded-less {
	@apply bg-black font-semibold text-[#fbdd21] py-1 px-3 hover:opacity-75 hover:bg-[#484a4b] rounded-[4px];
}

.btn-grey-rounded-disable {
	@apply py-1 px-3 bg-[#c2c6cc] text-white font-semibold rounded-[4px] shadow-md hover:bg-[#c2c6cc]/[0.7] focus:outline-none;
}

.btn-orange-rounded-less {
	@apply bg-gradient-to-br from-[#FCEE21] to-[#F7931E] font-semibold text-black py-1 px-3 hover:opacity-75 hover:bg-[#f7a21e] rounded-[4px];
}

.btn-orange-rounded-disable {
	@apply py-1 px-3 bg-[#c2c6cc] text-white font-semibold rounded-[4px] shadow-md hover:bg-[#c2c6cc]/[0.7] focus:outline-none;
}

.btn-orange-faded-rounded-less {
	@apply bg-[#dad2c4] font-semibold text-[#f7a21e] py-1 px-3 hover:opacity-75 rounded-[4px];
}

.btn-main {
	@apply bg-[#ebedef] font-semibold text-[#202020] py-1 px-3 rounded-[8px] border border-[#ebedef] hover:border-black;
}

.btn-main-disable {
	@apply bg-[#d9d9d9] font-semibold text-[#8a8a8e] py-1 px-3 rounded-[8px];
}

.btn-tab-rounded-less {
	@apply py-1 px-3 bg-gradient-to-br from-[#FCEE21] to-[#F7931E] text-black font-semibold rounded-[4px] shadow-md hover:opacity-75 focus:outline-none;
}

.btn-tab-disable {
	@apply flex flex-row justify-evenly items-center py-1 px-3 border-2 border-[#484a4b] font-semibold text-[#484a4b] rounded hover:bg-gradient-to-br hover:from-[#FCEE21] hover:to-[#F7931E] hover:text-black hover:bg-origin-border hover:border-transparent focus:outline-none;
}

/* Default Texts, Heading */
.page-title {
	@apply text-[#464E5F] font-dm_sansbold text-4xl mb-3 mt-1;
}

.heading-one {
	@apply text-black font-dm_sansbold text-8xl mb-3 mt-1;
}

.heading-two {
	@apply text-black font-dm_sansbold text-7xl mb-3 mt-1;
}

.heading-three {
	@apply text-black font-dm_sansbold text-6xl mb-3 mt-1;
}

.heading-four {
	@apply text-black font-dm_sansbold text-5xl mb-3 mt-1;
}

/* Select */
.select-primary {
	@apply bg-[#FFFFFF] w-full py-2 pl-1 pr-2 border border-solid border-[#e5e7eb] rounded-[5px] focus:outline-none;
}

.select-primary-error {
	@apply bg-[#FFFFFF] w-full py-2 pl-1 pr-2 border border-solid border-red-400 rounded-[5px] focus:outline-none;
}

.select-placeholder {
	@apply text-slate-300;
}

select:invalid,
select option[value=''] {
	color: #999999 !important;
}

/* textarea */
.textarea-primary {
	@apply bg-[#F3F3F3] px-2 py-3 w-full placeholder:text-[#9E9E9E] resize-none focus:outline-none;
}

.speech-bubble-admin {
	position: relative;
	background: #f3f3f9;
	border-radius: .4em;
}

.speech-bubble-admin:after {
	content: '';
	position: absolute;
	right: 0;
	top: 0%;
	width: 0;
	height: 0;
	border: 15px solid transparent;
	border-left-color: #f3f3f9;
	border-right: 0;
	border-top: 0;
	margin-top: 0px;
	margin-right: -10px;
}

.speech-bubble-user {
	position: relative;
	background: #F7931E;
	border-radius: .4em;
}

.speech-bubble-user:after {
	content: '';
	position: absolute;
	left: 0;
	top: 0%;
	width: 0;
	height: 0;
	border: 15px solid transparent;
	border-right-color: #F7931E;
	border-left: 0;
	border-top: 0;
	margin-top: 0px;
	margin-left: -10px;
}

@keyframes shine-lines-table {
	0% {
		opacity: 0.3;
	}

	40% {
		opacity: 0.6;
	}

	100% {
		opacity: 0.3;
	}
}

.td-1 span {
	width: calc(100% - 35px);
	height: 15px;
	border-radius: 20px;
	background-image: linear-gradient(90deg, #e4e4e4 0px, #e8e8e8 40px, #e4e4e4 80px);
	background-size: 300px;
	animation: shine-lines-table 1.6s infinite linear;
	display: inline-block;
	min-width: 15px;
}

.checkbox-select ::-webkit-scrollbar {
	-webkit-appearance: none;
	width: 10px;
}

.checkbox-select ::-webkit-scrollbar-thumb {
	border-radius: 2px;
	background-color: rgba(100, 100, 100, .5);
	box-shadow: 0 0 1px rgba(255, 255, 255, .5);
}

.horizontalSeparator {
	display: block;
	text-align: center;
	overflow: hidden;
	width: 100%;
	white-space: nowrap;
}

.horizontalSeparator>span {
	position: relative;
	display: inline-block;
	color: #ccc;
}

.horizontalSeparator>span:before,
.horizontalSeparator>span:after {
	content: "";
	position: absolute;
	top: 50%;
	width: 9999px;
	height: 1px !important;
	background: #ccc;
}

.horizontalSeparator>span:before {
	right: 100%;
	margin-right: 15px;
}

.horizontalSeparator>span:after {
	left: 100%;
	margin-left: 15px;
}

input[type="range"]:focus {
	outline: none;
}

input[type="range"] {
	-webkit-appearance: none;
	appearance: none;
	background: transparent;
	cursor: pointer;
	width: 15rem;
}

input[type="range"]::-webkit-slider-runnable-track {
	background-color: #75b4d4;
	border-radius: 0.5rem;
	height: 0.5rem;
}

input[type="range"]::-moz-range-track {
	background-color: #75b4d4;
	border-radius: 0.5rem;
	height: 0.5rem;
}

input[type="range"]::-webkit-slider-thumb {
	-webkit-appearance: none;
	appearance: none;
	margin-top: -7px;
	background-color: #F7931E;
	height: 1.4rem;
	width: 0.5rem;
}

input[type="range"]::-moz-range-thumb {
	border: none;
	border-radius: 0;
	background-color: #F7931E;
	height: 1.4rem;
	width: 0.5rem;
}

.custom-scrollbar::-webkit-scrollbar {
	width: 8px;
	height: 7px;
	border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
	background-color: #a4a4a4;
	border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-track {
	background-color: #ecf0f1;
	border-radius: 10px;
}

.ai-bg {
	background-image: url('../public/assets/ai-agents/ai-bg.svg');
}

.gradient-placeholder {
	background: linear-gradient(90deg, #f0f0f0 25%, #e0e0e0 50%, #f0f0f0 75%);
	background-size: 200% 100%;
	animation: gradientAnimation 1.5s infinite linear;
}

/* Keyframes for gradient animation */
@keyframes gradientAnimation {
	0% {
		background-position: 0% 0%;
	}

	100% {
		background-position: 100% 0%;
	}
}

.fadeInUp-appear,
.animate__animated {
	opacity: 0;
	transform: translate3d(0, 10%, 0)
}

.fadeInRight-appear {
	opacity: 0;
	transform: translate3d(5%, 0, 0)
}

.fadeInUp-appear-done,
.fadeInDown-appear-done,
.fadeInRight-appear-done {
	opacity: 1;
	transform: translateX(0);
	transition: opacity 250ms, transform 250ms;
}

.fadeInUpFast-appear-done {
	opacity: 1;
	transform: translateX(0);
	transition: opacity 50ms, transform 50ms;
}

input[type="radio"].radio-btn:checked:after {
	width: 15px;
	height: 15px;
	border-radius: 15px;
	top: -3px;
	left: -1px;
	position: relative;
	background-color: #f7931e;
	content: "";
	display: inline-block;
	visibility: visible;
	border: 2px solid white;
  }